import RightCheveron from '@/components/Icons/RightCheveron';
import TwelveColGrid from '@/components/TwelveColGrid';

import { TwelveColGridProps } from '@/lib/interfaces';
import { useRef, useState } from 'react';
import useMeasure from 'react-use-measure';

enum ARROWS {
  RIGHT,
  BOTH,
  LEFT,
}

export default function HorizontalScroll({
  items,
  addBlur = true,
  arrowStyleClassNames = 'text-white w-4.8 h-3.2 bg-[#808080] bg-opacity-50 rounded-[1.6rem] backdrop-blur-lg',
  ...props
}: TwelveColGridProps & {
  items: JSX.Element[];
  addBlur?: boolean;
  arrowStyleClassNames?: string;
}) {
  const [itemRef, { width: itemWidth }] = useMeasure();
  const [parentContainerRef, { width: parentContainerWidth }] = useMeasure();
  const containerScrollRef = useRef<HTMLDivElement | null>(null);
  const [arrows, setArrows] = useState(ARROWS.RIGHT);

  const moveForward = () => {
    if (!containerScrollRef.current) return;

    containerScrollRef.current.scrollLeft += itemWidth;
    setArrows(
      containerScrollRef.current.scrollLeft +
        itemWidth +
        parentContainerWidth >=
        containerScrollRef.current.scrollWidth
        ? ARROWS.LEFT
        : ARROWS.BOTH
    );
  };
  // console.log({
  //   itemWidth,
  //   sum: containerScrollRef?.current?.scrollLeft ?? 0 + parentContainerWidth,
  //   parentContainerWidth,
  //   scrollLeft: containerScrollRef?.current?.scrollLeft,
  //   scrollWidth: containerScrollRef?.current?.scrollWidth,
  // });

  const moveBackward = () => {
    if (!containerScrollRef.current) return;
    containerScrollRef.current.scrollLeft -= itemWidth;

    setArrows(
      containerScrollRef.current.scrollLeft - itemWidth <= 0
        ? ARROWS.RIGHT
        : ARROWS.BOTH
    );
  };

  const displayArrows = parentContainerWidth < itemWidth * items.length;

  const list = (() =>
    items.map((item, i) => (
      <div
        ref={i + 1 === items.length ? itemRef : undefined}
        key={item.key}
        className="flex-shrink-0"
      >
        {item}
      </div>
    )))();

  return (
    <TwelveColGrid {...props} addClassStyles="overflow-x-hidden">
      <div ref={parentContainerRef} className="relative col-span-12 group">
        <div
          ref={containerScrollRef}
          style={{ scrollBehavior: 'smooth' }}
          className="flex col-span-12 overflow-x-scroll flex-nowrap noscrollbar"
        >
          {list}
        </div>

        {displayArrows && (
          <>
            {(arrows === ARROWS.LEFT || arrows === ARROWS.BOTH) && (
              <>
                {addBlur && (
                  <div
                    style={{
                      background:
                        'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
                    }}
                    className="absolute h-full w-[16.8rem] left-0 top-0 z-10"
                  ></div>
                )}
                <button
                  onClick={moveBackward}
                  className={`top-1/2 opacity-0 group-hover:opacity-100 transition-opacity ease-in duration-150 -translate-y-1/2 left-0 absolute z-10 flex items-center justify-center rotate-180 ${arrowStyleClassNames}`}
                >
                  <RightCheveron hover="" />
                </button>
              </>
            )}
            {(arrows === ARROWS.RIGHT || arrows === ARROWS.BOTH) && (
              <button
                onClick={moveForward}
                className={`top-1/2 opacity-0 group-hover:opacity-100 transition-opacity ease-in duration-150 -translate-y-1/2 right-0 absolute z-10 flex items-center justify-center ${arrowStyleClassNames}`}
              >
                <RightCheveron hover="" />
              </button>
            )}
          </>
        )}
      </div>
    </TwelveColGrid>
  );
}
