import useIntersectionObserverRef from '@/hooks/useIntersectionObserverRef';
import { useState } from 'react';
import { animated, useSpring } from 'react-spring';

export default function FadeInUpSectionAnimation({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isInView, setIsInView] = useState(false);
  const setSectionViewed = () => setIsInView(true);
  const ref = useIntersectionObserverRef(isInView, setSectionViewed);
  const { opacity, t3d } = useSpring({
    from: {
      opacity: 0,
      t3d: 200,
    },
    to: {
      opacity: 1,
      t3d: 0,
    },
    cancel: !isInView,
  });
  return (
    <animated.div
      style={{
        opacity,
        transform: t3d.to((y) => `translate3d(0px, ${y}px, 0px)`),
      }}
      ref={ref}
    >
      {children}
    </animated.div>
  );
}
