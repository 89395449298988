import CtaButton from '@/components/CtaButton';
import CtaLink, { SecondaryCtaLink } from '@/components/CtaLink';
import FadeInUpSectionAnimation from '@/components/FadeInUpSectionAnimation';
import HorizontalScroll from '@/components/HorizontalScroll';
import RightCheveron from '@/components/Icons/RightCheveron';
import PageLayout from '@/components/PageLayout';
import Footer from '@/components/PageLayout/Footer';
import { NavBarDarkTheme } from '@/components/PageLayout/PageNavBar';
import TwelveColGrid from '@/components/TwelveColGrid';
import useUserV2 from '@/hooks/queries/auth_user/useUserV2';
import useInterval from '@/hooks/useInterval';
import { ArtistProfileHeader } from '@/lib/interfaces';
import {
  CLIENT_ARTIST_APPLICATIONS_URL,
  CLIENT_DISCOVER_URL,
  CLIENT_SIGNUP_URL,
  CLIENT_USER_COLLECTION_URL,
} from '@/utils/constants';
import { genQs } from '@/utils/v2HelperMethods';
import { getHomePageCarousel } from '@/utils/v2HttpRequests';
import { useDrag } from '@use-gesture/react';
import { GetServerSideProps } from 'next';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useState } from 'react';
import { animated, config, useSprings } from 'react-spring';

export const getServerSideProps: GetServerSideProps = async (_ctx) => {
  const { slides } = await getHomePageCarousel(
    genQs({ populate: { slides: { populate: { image: true } } } })
  );
  return {
    props: { slides },
  };
};

export default function IndexPage({
  slides,
}: {
  slides: ArtistProfileHeader[];
}) {
  return (
    <PageLayout addClassStyles="overflow-x-hidden">
      <NavBarDarkTheme />
      <CarouselSlides slides={slides} />
      <div className="flex justify-center bg-black">
        <FadeInUpSectionAnimation>
          <TwelveColGrid
            mx=""
            py="pt-3.2 pb-1.6 lgt:py-16.8"
            addClassStyles="bg-black text-white overflow-hidden"
            width="w-full max-w-screen-lgt"
          >
            <div className="col-span-12 lgt:col-span-4 px-1.6 lgt:px-0">
              <SectionHeader addClassStyles="mb-1.6 ">
                BECOME A COLLECTOR
              </SectionHeader>
              <SectionCopy>
                Discover the artists you love and unlock access to exclusive
                releases and rare artworks
              </SectionCopy>
            </div>
            <div className="col-span-12 lgt:col-span-7 lgt:col-start-6 lgt:row-span-2">
              <div className="relative mx-auto lgt:-mt-12 w-[41rem] h-[38rem] lgt:w-[64.8rem] lgt:h-[50.6rem]">
                <Image
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  src={
                    'https://res.cloudinary.com/hmkqf8kua/image/upload/v1646932770/IndexPage/unlock-demo_kkllpp.png'
                  }
                  alt="Unlock Demo"
                />
              </div>
            </div>
            <div className="col-span-12 lgt:col-span-4 px-1.6 lgt:px-0 self-end">
              <SecondaryCtaLink margin="mx-auto" href={CLIENT_DISCOVER_URL}>
                DISCOVER
              </SecondaryCtaLink>
            </div>
          </TwelveColGrid>
        </FadeInUpSectionAnimation>
      </div>
      <ManageYourCollectionSection />
      <div className="flex justify-center bg-grey-100">
        <FadeInUpSectionAnimation>
          <TwelveColGrid
            py="pt-3.2 pb-1.6 lgt:py-16.8"
            width="w-full max-w-screen-lgt"
          >
            <div className="col-span-12 mb-3.2 lgt:col-span-4">
              <SectionHeader addClassStyles="mb-1.6">
                ARTIST &amp; COLLECTOR CHATS
              </SectionHeader>
              <SectionCopy>
                Join private collector groups, connect to artists and recieve
                exclusive content and early access to releases
              </SectionCopy>
            </div>
            <div className="col-span-12 lgt:col-span-6 lgt:row-start-1 lgt:row-span-2 lgt:col-start-7">
              <div className="mb-20 lgt:mb-[7.5rem]">
                <div className="flex flex-row mb-0.8">
                  <div className="relative h-4.8 w-4.8 lgt:h-[6.8rem] lgt:w-[6.8rem]">
                    <Image
                      layout="fill"
                      objectFit="cover"
                      src={
                        'https://res.cloudinary.com/hmkqf8kua/image/upload/v1637854272/thumbnail_Kai_1285db55c1.png'
                      }
                      alt="Kai"
                    />
                  </div>
                  <div className="mx-0.8 max-w-[22.4rem] p-1.2 flex flex-col bg-white lgt:p-1.6 lgt:max-w-lg">
                    <h6 className="text-s mb-0.4">KAI</h6>
                    <p className="text-body">
                      Hey friends, thank you for all the support. I&apos;m going
                      to share a link to the waitlist later today
                    </p>
                  </div>
                </div>
                <div className="flex flex-row-reverse">
                  <div className="relative h-4.8 w-4.8 lgt:h-[7.2rem] lgt:w-[7.2rem]">
                    <Image
                      layout="fill"
                      objectFit="cover"
                      src={
                        'https://res.cloudinary.com/hmkqf8kua/image/upload/v1647020586/IndexPage/illya_wwnnqa.png'
                      }
                      alt="Ilya"
                    />
                  </div>
                  <div className="mx-0.8 max-w-[22.4rem] p-1.2 flex flex-col bg-white lgt:p-1.6 lgt:max-w-lg">
                    <h6 className="text-s mb-0.4 ">ILYA</h6>
                    <p className="text-body">Super excited!</p>
                  </div>
                </div>
              </div>

              <div className="flex mb-0.8">
                <div className="relative h-4.8 w-4.8 lgt:h-[7.2rem] lgt:w-[7.2rem] flex-shrink-0">
                  <Image
                    layout="fill"
                    objectFit="cover"
                    src={
                      'https://res.cloudinary.com/hmkqf8kua/image/upload/v1646059233/thumbnail_tumblr_pctbdo_W1_Jx1rodsuqo1_1280_20e7746202.jpg'
                    }
                    alt="RAFAEL SLIKS"
                  />
                </div>
                <div className="bg-white flex-wrap px-1.6 w-full flex flex-col justify-center">
                  <span className="w-full text-s">RAFAEL SLIKS</span>
                  <div className="flex justify-between text-xs">
                    <span>170 members</span>
                    <span>63 online</span>
                  </div>
                </div>
              </div>
              <div className="flex mb-0.8">
                <div className="relative h-4.8 w-4.8 lgt:h-[7.2rem] lgt:w-[7.2rem] flex-shrink-0">
                  <Image
                    layout="fill"
                    objectFit="cover"
                    src={
                      'https://res.cloudinary.com/hmkqf8kua/image/upload/v1637854272/thumbnail_Kai_1285db55c1.png'
                    }
                    alt="Kai"
                  />
                </div>
                <div className="bg-white flex-wrap px-1.6 w-full flex flex-col justify-center">
                  <span className="w-full text-s">KAI</span>
                  <div className="flex justify-between text-xs">
                    <span>93 members</span>
                    <span>34 online</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center lgt:justify-start lgt:items-end col-span-12 mt-0.8 lgt:col-span-4">
              <CtaButton disabled width="w-full max-w-[34.4rem]">
                COMING SOON
              </CtaButton>
            </div>
          </TwelveColGrid>
        </FadeInUpSectionAnimation>
      </div>
      <div className="flex justify-center bg-white mb-2.4">
        <FadeInUpSectionAnimation>
          <AboutOLEASection />
          <ForCollectorsSection />
          <ForArtistsSection />
        </FadeInUpSectionAnimation>
      </div>
      <Footer />
    </PageLayout>
  );
}

function CarouselSlides({ slides }: { slides: ArtistProfileHeader[] }) {
  const [currentSlideNum, setCurrentSlideNum] = useState(0);

  useInterval(() => {
    setCurrentSlideNum((prev) => (prev + 1 === slides.length ? 0 : prev + 1));
  }, 8000);

  const springs = useSprings(
    slides.length,
    slides.map((_v, _i) => ({
      from: { opacity: 0, touchAction: 'none' },
      to: { opacity: 1, touchAction: 'none' },
      config: config.molasses,
      reset: true,
    }))
  );

  // Set the drag hook and define component movement based on gesture data
  const bind = useDrag(
    ({ active, movement: [mx], direction: [xDir], velocity: [vx] }) => {
      const trigger = Math.abs(mx) > 8;

      const flyingOut = !active && trigger;
      // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      if (flyingOut) {
        setCurrentSlideNum((prev: number) => {
          if (prev === 0 && xDir === 1) return slides.length - 1;
          if (prev === slides.length - 1 && xDir === -1) return 0;
          return prev - xDir;
        });
      }
    }
  );

  return (
    <div className="h-[60rem] lgt:h-[76.8rem] w-full -mt-28 bg-black-100">
      {springs.map((styles, i) => {
        if (i !== currentSlideNum) return <div key={styles.opacity.id}></div>;
        return (
          <animated.div
            key={styles.opacity.id}
            style={styles}
            {...bind()}
            className="relative flex items-end justify-center h-full"
          >
            <Image
              layout="fill"
              priority
              objectFit="cover"
              src={slides[currentSlideNum].image.url}
              alt="Hayley Welsh"
            />
            <div
              style={{
                background: 'rgba(0, 0, 0, 0.3)',
              }}
              className="absolute w-full h-full"
            ></div>

            <TwelveColGrid
              gap="gap-x-0.8 lgt:gap-x-2.4"
              width="w-full max-w-screen-lgt"
              addClassStyles="text-white flex-shrink-0 relative"
            >
              <SectionHeader addClassStyles="col-span-12 lgt:col-span-4 lgt:col-start-1">
                {slides[currentSlideNum].title}
              </SectionHeader>

              <div className="col-span-12 lgt:col-span-4 lgt:col-start-1 mt-3.2 mb-5.6">
                <SecondaryCtaLink
                  href={slides[currentSlideNum].button_link_url}
                  width="w-full md:max-w-xl"
                >
                  {slides[currentSlideNum].button_copy}
                </SecondaryCtaLink>
              </div>
            </TwelveColGrid>
            <div className="absolute bottom-0.8 flex justify-center">
              {slides.map((s, ind) => {
                const isCurrentSlide = s.id === slides[currentSlideNum].id;
                const buttonBg = isCurrentSlide
                  ? 'bg-gray-200'
                  : 'bg-gray-200 opacity-50';
                return (
                  <div
                    key={s.id}
                    onClick={() => setCurrentSlideNum(ind)}
                    className={`w-3 cursor-pointer h-3 rounded-full mx-0.4 ${buttonBg}`}
                  ></div>
                );
              })}
            </div>
            <TwelveColGrid addClassStyles="absolute z-10 -translate-y-1/2 top-1/2 w-full">
              <div className="flex justify-between col-span-12">
                <CtaButton
                  onClick={() =>
                    setCurrentSlideNum((prev) => {
                      if (prev === 0) return slides.length - 1;
                      return prev - 1;
                    })
                  }
                  background=""
                  width=""
                >
                  <div className="rotate-180">
                    <RightCheveron large />
                  </div>
                </CtaButton>
                <CtaButton
                  onClick={() =>
                    setCurrentSlideNum((prev) => {
                      if (prev === slides.length - 1) return 0;
                      return prev + 1;
                    })
                  }
                  background=""
                  width=""
                >
                  <RightCheveron large />
                </CtaButton>
              </div>
            </TwelveColGrid>
          </animated.div>
        );
      })}
    </div>
  );
}

const forCollectorsAboutCards = (loggedIn: boolean) => [
  <AboutCard
    key={'ACCESS UNLOCKED'}
    title={'ACCESS UNLOCKED'}
    titleElement={
      <>
        ACCESS
        <br /> UNLOCKED
      </>
    }
    href={
      'https://res.cloudinary.com/hmkqf8kua/image/upload/v1647616773/IndexPage/discover-card_mzy88k.png'
    }
    copy={
      'Discover hundreds of artists and start collecting the rare artworks you love'
    }
  />,
  <AboutCard
    key={'COLLECTORS ONLY'}
    title={'COLLECTORS ONLY'}
    titleElement={
      <>
        COLLECTORS <br /> ONLY
      </>
    }
    copy={
      'Join a trusted, verfied community of collectors and support your favorite artists'
    }
    href={
      'https://res.cloudinary.com/hmkqf8kua/image/upload/v1647617241/IndexPage/chat-card_puknyo.png'
    }
  />,
  <AboutCard
    key={'PRIVATE ART MARKETPLACE'}
    title={'PRIVATE ART MARKETPLACE'}
    titleElement={<>PRIVATE ART MARKETPLACE</>}
    copy={'Purchase and sell artworks across a trusted, verified community'}
    href={
      'https://res.cloudinary.com/hmkqf8kua/image/upload/v1647617647/IndexPage/marketplace-card_lajhnk.png'
    }
  />,
  <div
    key="JOIN"
    className="h-40.0 w-[25.6rem] lgt:w-[26.6rem] lgt:h-[41.6rem] relative mr-0.8 flex-shrink-0 p-1.6"
  >
    <Image
      layout="fill"
      objectFit="cover"
      objectPosition="center"
      src={
        'https://res.cloudinary.com/hmkqf8kua/image/upload/v1647030816/IndexPage/join-gradient_fzaakk.png'
      }
      alt={'gradient color background'}
    />
    <div className="relative flex flex-col justify-between w-full h-full">
      <h6 className="text-mobile-l mb-0.8 relative">JOIN</h6>

      <Link
        href={loggedIn ? CLIENT_USER_COLLECTION_URL : CLIENT_SIGNUP_URL}
        className="flex justify-end"
      >
        <RightCheveron large />
      </Link>
    </div>
  </div>,
];

const forArtistAboutCards = [
  ...[
    {
      title: 'GAIN INDEPENDENCE',
      titleElement: (
        <>
          GAIN <br /> INDEPENDENCE
        </>
      ),
      copy: 'Unlock financial stabiility by having direct access to your collector demand queue',
      href: 'https://res.cloudinary.com/hmkqf8kua/image/upload/v1647617733/IndexPage/artist-dashboard-card_gm4dqg.png',
    },
    // {
    //   title: 'CONNECT WITH YOUR COLLECTORS',
    //   titleElement: <>CONNECT WITH YOUR COLLECTORS</>,
    //   copy: 'Communicate, share content and give access to collectors via private groups',
    //   href: 'https://res.cloudinary.com/hmkqf8kua/image/upload/v1647617756/IndexPage/artist-groups-card_j6ntyg.png',
    // },
    {
      title: 'ACCESS TOOLS BUILT FOR ARTISTS',
      titleElement: <>ACCESS TOOLS BUILT FOR ARTISTS</>,
      copy: 'Tools to support your next show, release or event, gain transparency and insights',
      href: 'https://res.cloudinary.com/hmkqf8kua/image/upload/v1647617772/IndexPage/artist-tools-card_dxm6b5.png',
    },
    {
      title: 'METAVERSE READY',
      titleElement: (
        <>
          METAVERSE <br /> READY
        </>
      ),
      copy: 'Explore your next NFT, blockchain idea or location based experience with OLEA',
      href: 'https://res.cloudinary.com/hmkqf8kua/image/upload/v1647617785/IndexPage/artist-metaverse-card_xdtadl.png',
    },
  ].map(({ title, titleElement, copy, href }) => (
    <AboutCard
      key={title}
      href={href}
      title={title}
      titleElement={titleElement}
      copy={copy}
    />
  )),
  <div
    key="APPLY"
    className="h-40.0 w-[25.6rem] lgt:w-[26.6rem] lgt:h-[41.6rem] relative mr-0.8 flex-shrink-0 p-1.6"
  >
    <Image
      layout="fill"
      objectFit="cover"
      objectPosition="center"
      src={
        'https://res.cloudinary.com/hmkqf8kua/image/upload/v1647030816/IndexPage/join-gradient_fzaakk.png'
      }
      alt={'gradient color background'}
    />
    <div className="relative flex flex-col justify-between w-full h-full">
      <h6 className="text-mobile-l mb-0.8 relative">APPLY</h6>

      <Link href={CLIENT_ARTIST_APPLICATIONS_URL} className="flex justify-end">
        <RightCheveron large />
      </Link>
    </div>
  </div>,
];

const SectionHeader = ({
  addClassStyles,
  children,
}: {
  addClassStyles?: string;
  children: React.ReactNode;
}) => <h3 className={`copy-xl ${addClassStyles}`}>{children}</h3>;

const SectionCopy = ({ children }: { children: React.ReactNode }) => (
  <p className="text-body">{children}</p>
);

function AboutCard({
  href,
  title,
  titleElement,
  copy,
}: {
  href: string;
  title: string;
  titleElement: JSX.Element;
  copy: string;
}) {
  return (
    <div className="h-40.0 w-[25.6rem] lgt:w-[26.6rem] lgt:h-[41.6rem] relative bg-black hover:bg-black-100 transition-colors text-white mr-0.8 lgt:mr-[2.4rem] flex-shrink-0 p-1.6">
      <Image
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        src={href}
        alt={title}
      />
      <h6 className="text-mobile-l mb-0.8 relative">{titleElement}</h6>
      <div className="relative text-white text-s">{copy}</div>
    </div>
  );
}

function ForCollectorsSection() {
  const { data: user } = useUserV2();
  return (
    <>
      <TwelveColGrid>
        <div className="col-span-12 pl-1.6  lgt:pl-0 mb-2.4">
          <SectionHeader>
            <span className="text-mobile-l">FOR COLLECTORS</span>
          </SectionHeader>
        </div>
      </TwelveColGrid>

      <HorizontalScroll gap="gap-0.8" items={forCollectorsAboutCards(!!user)} />
    </>
  );
}
function ForArtistsSection() {
  return (
    <>
      <TwelveColGrid>
        <div className="col-span-12 pl-1.6 lgt:pl-0 mb-2.4">
          <SectionHeader addClassStyles="mt-2.4">
            <span className="text-mobile-l">FOR ARTISTS</span>
          </SectionHeader>
        </div>
      </TwelveColGrid>

      <HorizontalScroll
        my="mb-1.6 lgt:mb-16.8"
        gap="gap-0.8"
        items={forArtistAboutCards}
      />
    </>
  );
}

function AboutOLEASection() {
  return (
    <TwelveColGrid
      mx=""
      py="pt-3.2 lgt:pt-16.8"
      width="w-full max-w-screen-lgt"
      gap="gap-0.8 lgt:gap-2.4"
    >
      <div className="col-span-12 lgt:col-span-4 mb-0.8 px-1.6">
        <SectionHeader>ABOUT OLEA</SectionHeader>
      </div>
      <div className="col-span-12 lgt:col-span-4 lgt:col-start-8 mb-5.6 px-1.6">
        <SectionCopy>
          WE CONNECT ARTISTS WITH COLLECTORS WORLDWIDE, HELPING THEM FORGE
          STRONG RELATIONSHIPS THAT FACILITATE THE CREATION AND DISTRIBUTION OF
          ART
          <br />
          <br />
          For Artists, OLEA provides access to tools built for artists to help
          them gain independence and to receive fair value from their work
          <br />
          <br />
          For Collectors, OLEA is a personalized space to connect with artists,
          discover new artwork and to manage your collection all in one place
        </SectionCopy>
      </div>
    </TwelveColGrid>
  );
}

function ManageYourCollectionSection() {
  const { data: user } = useUserV2();
  return (
    <div className="flex justify-center">
      <FadeInUpSectionAnimation>
        <TwelveColGrid
          py="pt-3.2 pb-1.6 lgt:py-16.8"
          width="w-full max-w-screen-lgt"
          gap="gap-0.8 lgt:gap-2.4"
        >
          <div className="col-span-12 mb-3.2 lgt:col-span-5 lgt:col-start-8 lgt:row-start-1">
            <SectionHeader addClassStyles="mb-1.6 ">
              MANAGE YOUR COLLECTION
            </SectionHeader>
            <SectionCopy>
              View, track, manage and grow your collection of artwork all from
              one place
            </SectionCopy>
          </div>
          <div className="col-span-6 place-self-end lgt:col-start-1 lgt:row-start-1 lgt:col-span-3">
            <div className="h-16.8 w-16.8 lgt:h-26.4 lgt:w-26.4 bg-grey-100 p-1.2 flex justify-center items-center ">
              <div className="flex flex-col items-start justify-between w-full h-full">
                <div>
                  <h6 className="mb-0.8">
                    <span className="block copy-l">TYLER</span>
                    <span className="block copy-l">LAURENT</span>
                  </h6>
                  <p className="text-s">SINCE 2021</p>
                </div>
                <div className="flex justify-between w-full">
                  <p className="text-s">27 PIECES</p>
                  <p className="text-s">$153,090 / +89%</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-6 lgt:col-start-4 lgt:row-start-1 lgt:col-span-3">
            <div className="h-16.8 w-16.8 lgt:h-26.4 lgt:w-26.4 bg-grey-100 flex justify-center items-center p-1.6">
              <div
                style={{
                  filter:
                    'drop-shadow(0px 15px 45px rgba(0, 0, 0, 0.16)) drop-shadow(0px 1.87823px 7.30127px rgba(0, 0, 0, 0.08))',
                }}
                className="relative w-full h-full "
              >
                <Image
                  layout="fill"
                  objectFit="contain"
                  src={
                    'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619103437/691_9aaf1ed6b7.jpg'
                  }
                  alt="Arsham Eroded Jupiter"
                />
              </div>
            </div>
          </div>
          <div className="col-span-6 place-self-end lgt:col-start-1 lgt:row-start-2 lgt:place-self-start">
            <div className="h-16.8 w-16.8 lgt:h-26.4 lgt:w-26.4 bg-grey-100 flex justify-center items-center p-1.6">
              <div
                style={{
                  filter:
                    'drop-shadow(0px 15px 45px rgba(0, 0, 0, 0.16)) drop-shadow(0px 1.87823px 7.30127px rgba(0, 0, 0, 0.08))',
                }}
                className="relative w-full h-full "
              >
                <Image
                  layout="fill"
                  objectFit="contain"
                  src={
                    'https://res.cloudinary.com/hmkqf8kua/image/upload/v1639881114/Screenshot_2021_12_18_213128_dcb7bd3672.png'
                  }
                  alt="Jerkface SuperJerk (Variant)"
                />
              </div>
            </div>
          </div>
          <div className="col-span-6 lgt:col-start-4 lgt:row-start-2">
            <div className="h-16.8 w-16.8 lgt:h-26.4 lgt:w-26.4 bg-grey-100 flex justify-center items-center p-1.6">
              <div
                style={{
                  filter:
                    'drop-shadow(0px 15px 45px rgba(0, 0, 0, 0.16)) drop-shadow(0px 1.87823px 7.30127px rgba(0, 0, 0, 0.08))',
                }}
                className="relative w-full h-full"
              >
                <Image
                  layout="fill"
                  objectFit="contain"
                  src={
                    'https://res.cloudinary.com/hmkqf8kua/image/upload/v1619105990/1743_0872afa73b.jpg'
                  }
                  alt="Cleon Peterson, So It Goes(White)"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center col-span-12 mt-0.8 lgt:col-span-5 lgt:col-start-8 lgt:row-start-2 lgt:justify-start lgt:items-end">
            {user ? (
              <CtaLink
                width="w-full max-w-[34.4rem]"
                href={CLIENT_USER_COLLECTION_URL}
              >
                VIEW COLLECTION
              </CtaLink>
            ) : (
              <CtaLink width="w-full max-w-[34.4rem]" href={CLIENT_SIGNUP_URL}>
                SIGN UP
              </CtaLink>
            )}
          </div>
        </TwelveColGrid>
      </FadeInUpSectionAnimation>
    </div>
  );
}
