import Instagram from '@/components/Icons/Instagram';
import RightCheveron from '@/components/Icons/RightCheveron';
import OleaLogoLink from '@/components/PageLayout/OleaLogoLink';
import TwelveColGrid from '@/components/TwelveColGrid';
import {
  CLIENT_ABOUT_URL,
  CLIENT_ARTIST_APPLICATIONS_URL,
  CLIENT_DISCOVER_URL,
  CLIENT_FAQ_URL,
  CLIENT_PRIVACY_POLICY_URL,
  CLIENT_TERMS_AND_CONDITIONS_URL,
  CLIENT_USER_COLLECTION_URL,
  INSTAGRAM_URL,
  MAILTO_EMAIL,
} from '@/utils/constants';
import { getCurrentYear } from '@/utils/v2HelperMethods';
import Link from 'next/link';

type FooterLink = {
  title: string;
  href: string;
  colSpan: string;
};

const links: FooterLink[] = [
  {
    title: 'ABOUT',
    href: CLIENT_ABOUT_URL,
    colSpan: 'col-span-12 lgt:col-span-2',
  },
  { title: 'FAQ', href: CLIENT_FAQ_URL, colSpan: 'col-span-12 lgt:col-span-2' },
  {
    title: 'DISCOVER',
    href: CLIENT_DISCOVER_URL,
    colSpan: 'col-span-12 lgt:col-span-8',
  },
  {
    title: 'COLLECTION MANAGEMENT',
    href: CLIENT_USER_COLLECTION_URL,
    colSpan: 'col-span-12 lgt:hidden',
  },
  {
    title: 'USER TERMS',
    href: CLIENT_TERMS_AND_CONDITIONS_URL,
    colSpan: 'col-span-12 lgt:col-span-2',
  },
  {
    title: 'CONTACT US',
    href: MAILTO_EMAIL,
    colSpan: 'col-span-12 lgt:col-span-2',
  },
  {
    title: 'COLLECTION MANAGEMENT',
    href: CLIENT_USER_COLLECTION_URL,
    colSpan: 'hidden lgt:block col-span-8',
  },
  {
    title: 'PRIVACY POLICY',
    href: CLIENT_PRIVACY_POLICY_URL,
    colSpan: 'col-span-12 lgt:col-span-2',
  },
  {
    title: 'ARTIST INQUIRY',
    href: CLIENT_ARTIST_APPLICATIONS_URL,
    colSpan: 'col-span-12 lgt:col-span-2',
  },
];

export default function Footer() {
  return (
    <div className="bg-grey-100 dark:bg-black-200">
      <TwelveColGrid py="pt-6.4 lgt:pt-4.8">
        <OleaLogoLink
          height={22}
          priority={false}
          addClassStyles={'col-span-10 lgt:mb-0 dark:invert'}
        />

        <div className="flex justify-end col-span-2 ">
          <Link href={INSTAGRAM_URL}>
            <Instagram />
          </Link>
        </div>
      </TwelveColGrid>
      <TwelveColGrid py="pb-6.4 lgt:pb-4.8" my="mt-4.8">
        {links.map(({ title, href, colSpan }, i) => (
          <div key={title + i} className={colSpan}>
            <Link
              href={href}
              className="py-1.6 flex justify-between items-center text-s font-medium text-black-100 dark:text-white border-b border-grey-300 dark:border-black-300  lgt:border-none"
            >
              <span>{title}</span>
              <span className="lgt:hidden">
                <RightCheveron />
              </span>
            </Link>
          </div>
        ))}

        <p className="mt-4.8 col-span-12 text-xs font-medium text-center text-grey-300 dark:text-black-300 lgt:col-span-4 lgt:col-start-9 lgt:flex lgt:items-end lgt:justify-end">
          © OLEA {getCurrentYear}, ALL RIGHTS RESERVED
        </p>
      </TwelveColGrid>
    </div>
  );
}
